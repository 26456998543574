import { Icon } from '@iconify/react';
import { styled, alpha } from '@material-ui/core/styles';
import { Divider, Stack, Box, Grid, Container, Typography, IconButton, Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Logo from 'src/components/Logo';
// hooks
import googleFill from '@iconify/icons-eva/google-fill';
import twitterFill from '@iconify/icons-eva/twitter-fill';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import useMobile from 'src/hooks/useMobile';
import { PATH_PAGE } from 'src/routes/paths';

const SOCIALS = [
  { name: 'FaceBook', icon: facebookFill },
  { name: 'Google', icon: googleFill },
  { name: 'Linkedin', icon: linkedinFill },
  { name: 'Twitter', icon: twitterFill }
];

const NAVIGATION = {
  sections: [
    {
      heading: 'Shoppy Monkey',
      links: [
        {
          title: 'Contact',
          to: PATH_PAGE.contactUs
        },
        {
          title: 'Privacy Policy',
          to: PATH_PAGE.legal.privacyPolicy
        },
        {
          title: 'Terms and Conditions',
          to: PATH_PAGE.legal.termsAndConditions
        }
      ]
    },
    {
      heading: 'Resources',
      links: [
        {
          title: 'Articles',
          to: PATH_PAGE.articles.root
        },
        {
          title: 'E-Commerce',
          to: PATH_PAGE.info.ecommerce.overview
        },
        {
          title: 'Selling Globally',
          to: PATH_PAGE.info.ecommerce.currencies
        }
      ]
    },
    {
      heading: 'Support',
      links: [
        {
          title: `FAQ's`,
          to: PATH_PAGE.faqs
        },
        {
          title: 'Service Status',
          to: PATH_PAGE.support.serviceStatus
        }
      ]
    }
  ]
};

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(15, 0),
  backgroundImage: `linear-gradient(200deg, ${theme.palette.grey[800]} 30%, ${alpha(
    theme.palette.grey[800],
    0.9
  )} 100%)`
}));

// ----------------------------------------------------------------------

const LinkHeaderText = ({ children, sx }) => (
  <Typography
    variant="body2"
    color="primary"
    sx={{
      textAlign: 'start',
      fontWeight: 600,
      ...sx
    }}
  >
    {children}
  </Typography>
);

const LinkText = ({ column, sx }) => (
  <RouterLink key={column.title} to={column.to} color="inherit" style={{ textDecoration: 'none' }}>
    <Typography
      variant="body2"
      noWrap
      sx={{
        textAlign: 'start',
        color: '#9aa3ac',
        ...sx
      }}
    >
      {column.title}
    </Typography>
  </RouterLink>
);

export default function MainFooter() {
  const isMobile = useMobile();
  return (
    <RootStyle sx={{ ...(isMobile && { px: 5 }) }}>
      <Container maxWidth="lg" sx={{ position: 'relative', textAlign: 'center' }}>
        <Stack direction="column" spacing={isMobile ? 5 : 10} sx={{ width: '100%' }}>
          <Stack direction={!isMobile ? 'row' : 'column'} spacing={isMobile ? 7 : 3}>
            <Box sx={{ width: '100%' }}>
              <Grid container spacing={isMobile ? 8 : 6} direction="row" justifyContent="start">
                {NAVIGATION.sections.map((section) => {
                  return (
                    <Grid key={section.heading} item xs={12} sm={4}>
                      <Stack spacing={2} direction="column" justifyContent="start">
                        <LinkHeaderText {...(isMobile && { sx: { textAlign: 'center' } })}>
                          {section.heading}
                        </LinkHeaderText>
                        <Stack spacing={1} direction="column" justifyContent="start">
                          {section.links.map((column) => (
                            <LinkText
                              key={column.title}
                              column={column}
                              {...(isMobile && { sx: { textAlign: 'center' } })}
                            />
                          ))}
                        </Stack>
                      </Stack>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>

            <Box sx={{ alignSelf: isMobile ? 'center' : 'start', p: 0 }}>
              <Button component={RouterLink} to={PATH_PAGE.home} sx={{ p: 0 }}>
                <Logo
                  logo={{
                    variant: '#9aa3ac',
                    contrast: '#9aa3ac',
                    collapsedHeight: 35
                  }}
                  isLogo
                />
              </Button>
            </Box>
          </Stack>

          <Box>
            <Divider />

            <Stack
              direction={isMobile ? 'column' : 'row'}
              justifyContent="space-between"
              {...(isMobile && { spacing: 4 })}
              sx={{ mt: 4, mb: { xs: 5, sm: 0 } }}
            >
              <Stack spacing={0.5}>
                <Typography variant="caption" color="#9aa3ac">
                  © All rights reserved
                </Typography>
                <Typography variant="body2" color="primary">
                  Shoppy Monkey
                </Typography>
              </Stack>

              <Stack spacing={1.5} direction="row-reverse" justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                {SOCIALS.map((social) => (
                  <IconButton key={social.name} color="primary" sx={{ p: 1 }}>
                    <Icon icon={social.icon} width={16} height={16} />
                  </IconButton>
                ))}
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Container>
    </RootStyle>
  );
}
