import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { useState, useEffect, useRef } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import arrowIosUpwardFill from '@iconify/icons-eva/arrow-ios-upward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { styled } from '@material-ui/core/styles';
import { Typography, Box, Link, Grid, Button, Stack, Popover, Divider } from '@material-ui/core';

// ----------------------------------------------------------------------

const LinkStyle = styled(Link)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.primary,
  marginRight: theme.spacing(5),
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shortest
  }),
  '&:hover': {
    opacity: 0.48,
    textDecoration: 'none'
  }
}));

const LinkItemSelectedDot = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignSelf: 'center',
  height: 5,
  width: 5,
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.shape.borderRadiusCircle,
  marginRight: theme.spacing(0.5)
}));

// ----------------------------------------------------------------------

IconBullet.propTypes = {
  type: PropTypes.oneOf(['subheader', 'item'])
};

function IconBullet({ type = 'item' }) {
  return (
    <Box sx={{ width: 24, height: 16, display: 'flex', alignItems: 'center' }}>
      <Box
        component="span"
        sx={{
          ml: '2px',
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'currentColor',
          ...(type !== 'item' && {
            ml: 0,
            width: 8,
            height: 2,
            borderRadius: 2
          })
        }}
      />
    </Box>
  );
}

const ButtonStyle = styled(Button)(({ theme }) => ({
  padding: theme.spacing(2),
  minHeight: 48,
  height: 'auto',
  borderRadius: theme.shape.borderRadius,
  textTransform: 'none'
}));

const NavigationView = ({ pathname, children }) => (
  <Grid container spacing={0}>
    {children.map((list, index) => {
      const { subheader, items } = list;

      return (
        <Grid key={subheader} item xs={12}>
          <Box sx={{ backgroundColor: 'grey.100' }}>
            <Stack spacing={1} sx={{ p: (theme) => theme.spacing(4, 3) }}>
              <Typography variant="overline" color="primary" sx={{ ml: 2 }}>
                {subheader}
              </Typography>

              <Box>
                <Grid container spacing={1} direction="row">
                  {items.map((item) => (
                    <Grid key={item.title} item xs={12} md={6}>
                      <ButtonStyle LinkComponent={RouterLink} to={item.path} variant="text">
                        <Stack direction="row" spacing={1}>
                          <LinkItemSelectedDot
                            sx={{
                              ...(item.path !== pathname && { display: 'none' })
                            }}
                          />
                          <Stack spacing={0.5}>
                            <Typography variant="body2" color="grey.900">
                              {item.title}
                            </Typography>
                            {item.subtitle && (
                              <Typography variant="subtitle2" color="grey.500" sx={{ fontWeight: 400 }}>
                                {item.subtitle}
                              </Typography>
                            )}
                          </Stack>
                        </Stack>
                      </ButtonStyle>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Stack>
            {index !== children.length && <Divider />}
          </Box>
        </Grid>
      );
    })}
  </Grid>
);

const MenuDesktopItem = ({ item, pathname, isFullscreen, isDark, isOpen, isOffset, onOpen, onClose }) => {
  const anchorRef = useRef(null);
  const { title, path, children } = item;
  const isActive = pathname === path;
  const commonSxProps = {
    ...(isFullscreen && { color: 'common.white' }),
    ...(isOffset && { color: isDark ? 'common.white' : 'text.primary' }),
    ...(isActive && { color: 'primary.main' })
  };

  if (children) {
    return (
      <div key={title}>
        <LinkStyle
          ref={anchorRef}
          onClick={() => onOpen(title)}
          sx={{
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',
            ...commonSxProps
          }}
        >
          {title}
          <Box
            component={Icon}
            icon={isOpen ? arrowIosUpwardFill : arrowIosDownwardFill}
            sx={{ ml: 0.5, width: 16, height: 16 }}
          />
        </LinkStyle>

        <Popover
          open={isOpen}
          anchorEl={anchorRef.current}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={onClose}
          PaperProps={{
            sx: {
              p: 0,
              mt: 5,
              maxWidth: 700,
              borderRadius: 2,
              boxShadow: (theme) => theme.customShadows.z24
            }
          }}
        >
          <NavigationView pathname={pathname} children={children} />
        </Popover>
      </div>
    );
  }

  return (
    <LinkStyle to={path} component={RouterLink} sx={commonSxProps}>
      {title}
    </LinkStyle>
  );
};

MenuDesktop.propTypes = {
  isOffset: PropTypes.bool,
  isFullscreen: PropTypes.bool,
  isDark: PropTypes.bool,
  navConfig: PropTypes.array
};

export default function MenuDesktop({ isOffset, isFullscreen, isDark, navConfig }) {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(null);

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpen = (title) => {
    setOpen(title);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <Stack direction="row" sx={{ position: 'relative' }}>
      {navConfig.map((link) => (
        <MenuDesktopItem
          key={link.title}
          item={link}
          pathname={pathname}
          isOpen={open === link.title}
          onOpen={handleOpen}
          onClose={handleClose}
          isOffset={isOffset}
          isFullscreen={isFullscreen}
          isDark={isDark}
        />
      ))}
    </Stack>
  );
}
