import PropTypes from 'prop-types';
import { createContext, useCallback, useState } from 'react';
import { CHECKOUT_STAGE_OPTIONS } from 'src/pages/admin_v2/editor/checkout/form-components/CheckoutStageSelector';

// ----------------------------------------------------------------------
export const MOBILE_KEY = 'mobile';
export const DESKTOP_KEY = 'desktop';

const initialState = {
  sidebar: null,
  previewKey: DESKTOP_KEY,
  isMobileView: false,
  editDrawer: {
    open: false,
    component: null
  },
  collectionPreview: null,
  productPreview: null,
  checkoutStagePreview: CHECKOUT_STAGE_OPTIONS[0].value,
  onChangeSidebar: () => {},
  onUpdateEditorDrawer: () => {},
  onMobileView: () => {},
  onDesktopView: () => {},
  onChangeCollectionPreview: () => {},
  onChangeProductPreview: () => {},
  onChangeCheckoutStagePreview: () => {}
};

const EditorContext = createContext(initialState);

EditorProvider.propTypes = {
  children: PropTypes.node
};

function EditorProvider({ children }) {
  const [editor, setEditor] = useState({
    // Change sidebar for different pages (e.g. Home, Contact Us)
    sidebar: initialState.sidebar,
    // Sidebar Editor
    editDrawer: initialState.editDrawer
  });
  const [view, setView] = useState({
    previewKey: initialState.previewKey,
    // Force the mobile view
    isMobileView: initialState.isMobileView
  });

  /**
   * When viewing the collection editor you can change the collection and this is used to keep track the selected option.
   * The context required and the two components do not directly interact with each other.
   */
  const [collectionPreview, setCollectionPreview] = useState();
  const [productPreview, setProductPreview] = useState();
  const [checkoutStagePreview, setCheckoutStagePreview] = useState(initialState.checkoutStagePreview);

  const handleChangeSidebar = useCallback(
    (component) => {
      setEditor((prev) => {
        return {
          ...prev,
          sidebar: component
        };
      });
    },
    [setEditor]
  );

  const handleUpdateEditorDrawer = useCallback(
    (values) => {
      setEditor((prev) => {
        return {
          ...prev,
          editDrawer: values
        };
      });
    },
    [setEditor]
  );

  const handleMobileView = useCallback(
    (key) => {
      setView((prev) => {
        return {
          ...prev,
          previewKey: key,
          isMobileView: true
        };
      });
    },
    [setView]
  );

  const handleDesktopView = useCallback(
    (key) => {
      setView((prev) => {
        return {
          ...prev,
          previewKey: key,
          isMobileView: false
        };
      });
    },
    [setView]
  );

  const handleChangeCollectionPreview = useCallback((option) => setCollectionPreview(option), [setCollectionPreview]);

  const handleChangeProductPreview = useCallback(
    (option) => {
      setProductPreview(option);
    },
    [setProductPreview]
  );

  const handleCheckoutStagePreview = useCallback((value) => setCheckoutStagePreview(value), [setCheckoutStagePreview]);

  return (
    <EditorContext.Provider
      value={{
        ...editor,
        ...view,
        collectionPreview,
        productPreview,
        checkoutStagePreview,
        // Sidebar
        onChangeSidebar: handleChangeSidebar,
        onUpdateEditorDrawer: handleUpdateEditorDrawer,
        onMobileView: handleMobileView,
        onDesktopView: handleDesktopView,
        onChangeCollectionPreview: handleChangeCollectionPreview,
        onChangeProductPreview: handleChangeProductPreview,
        onChangeCheckoutStagePreview: handleCheckoutStagePreview
      }}
    >
      {children}
    </EditorContext.Provider>
  );
}

export { EditorProvider, EditorContext };
