import { useCallback, useEffect, useState } from 'react';
import { compact, defaultTo, uniq } from 'lodash';
import { Button, Container, Stack, Divider, Typography } from '@material-ui/core';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { getSubscriptionPlans } from 'src/redux/slices/plan';
import PricingPlans from 'src/components/_admin_v2/subscription/PricingPlans';
import GenericComponentError from 'src/components/_admin_v2/GenericComponentError';
import { CURRENCIES } from 'src/components/_admin_v2/subscription/constants';
import { useDispatch, useSelector } from '../../redux/store';
// routes
import { PATH_DASHBOARD, PATH_PAGE } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';

export const PlansChoosePlanView = ({ onChangePlan, onCreatePlan }) => {
  const dispatch = useDispatch();
  const [currency, setCurrency] = useState(CURRENCIES[0].value);
  const {
    loading: { plansLoading },
    error: { plansError },
    plans
  } = useSelector((state) => state.plan);
  const availableCurrencies = uniq(compact(defaultTo(plans?.options, []).map((o) => o.currency?.code)));
  const enableCurrencyPicker = availableCurrencies.length > 1;

  useEffect(() => {
    dispatch(getSubscriptionPlans());
  }, [dispatch]);

  const handleAction = useCallback(
    (id) => {
      if (plans?.id) {
        onChangePlan(id);
      } else {
        onCreatePlan(id);
      }
    },
    [plans, onChangePlan, onCreatePlan]
  );

  const handleChangeCurrency = useCallback((value) => setCurrency(value), [setCurrency]);

  return (
    <>
      {plansError && (
        <GenericComponentError description="At this moment we cannot load available plans, please give a few moments and try again" />
      )}
      {!plansError && (
        <Stack direction="column" spacing={6}>
          <PricingPlans
            enablePicker={enableCurrencyPicker}
            currency={currency}
            isLargeView={false}
            isLoggedIn
            loading={plansLoading}
            plans={plans?.options}
            currentPlan={plans?.id}
            handleAction={handleAction}
            handleChangeCurrency={handleChangeCurrency}
          />

          <Divider orientation="horizontal" variant="fullWidth" flexItem>
            <Button variant="outlined" component={RouterLink} to={PATH_PAGE.pricing} target="_blank" color="primary">
              <Typography variant="body2" noWrap>
                <strong>Compare All Plan Features</strong>
              </Typography>
            </Button>
          </Divider>
        </Stack>
      )}
    </>
  );
};

export default function PlansChoosePlan() {
  const navigate = useNavigate();
  const { themeStretch } = useSettings();

  const handleChangePlan = useCallback((id) => navigate(PATH_DASHBOARD.plans.changePlan(id)), [navigate]);
  const handleCreatePlan = useCallback((id) => navigate(PATH_DASHBOARD.plans.payment(id)), [navigate]);

  return (
    <Page title="Plans | Choose Plan">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <>
          <HeaderBreadcrumbs
            heading="Choose Plan"
            links={[{ name: 'Home', href: PATH_DASHBOARD.root }, { name: 'Plans' }]}
            action={
              <Button variant="contained" component={RouterLink} to={PATH_DASHBOARD.setting.plan}>
                View Current Plan
              </Button>
            }
          />
          <PlansChoosePlanView onChangePlan={handleChangePlan} onCreatePlan={handleCreatePlan} />
        </>
      </Container>
    </Page>
  );
}
