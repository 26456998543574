// routes
import { PATH_DASHBOARD, PATH_PAGE } from '../../routes/paths';

// ----------------------------------------------------------------------

const getMenuConfig = (isAuthenticated) => {
  return [
    {
      title: 'Pricing',
      path: isAuthenticated ? PATH_DASHBOARD.plans.root : PATH_PAGE.pricing
    },
    {
      title: 'Get Started',
      path: PATH_PAGE.info.ecommerce.overview
    },
    {
      title: 'Resources',
      children: [
        {
          subheader: 'News',
          items: [
            {
              title: 'Articles',
              subtitle: 'All the latest stories on everything in the world of commerce',
              path: PATH_PAGE.articles.root
            }
          ]
        },
        {
          subheader: 'Support',
          items: [
            {
              title: 'Payment Gateways',
              subtitle: 'A complete list of supported payment gateways',
              path: PATH_PAGE.general.paymentGateways
            },
            {
              title: `FAQ's`,
              subtitle: 'All the questions frequently asked by our customers and the community',
              path: PATH_PAGE.faqs
            }
          ]
        }
      ]
    }
  ];
};

export default getMenuConfig;
