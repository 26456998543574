import { Stack, Skeleton } from '@material-ui/core';
import { PricingPlanRootStyle } from './PricingPlanCard';

const SkeletonCard = () => (
  <PricingPlanRootStyle sx={{ height: 670, width: '100%', maxWidth: 480 }}>
    <Stack spacing={8} sx={{ width: '100%' }}>
      <Stack spacing={1}>
        <Skeleton variant="rectangular" width="50%" sx={{ height: 30, borderRadius: 1 }} />
        <Skeleton variant="rectangular" width="100%" sx={{ height: 30, borderRadius: 1 }} />
        <Skeleton variant="rectangular" width="100%" sx={{ height: 30, borderRadius: 1 }} />
      </Stack>

      <Stack spacing={2}>
        <Skeleton variant="rectangular" width="100%" sx={{ height: 15, borderRadius: 1 }} />
        <Skeleton variant="rectangular" width="100%" sx={{ height: 15, borderRadius: 1 }} />
        <Skeleton variant="rectangular" width="100%" sx={{ height: 15, borderRadius: 1 }} />
      </Stack>

      <Stack spacing={2}>
        <Skeleton variant="rectangular" width="100%" sx={{ height: 15, borderRadius: 1 }} />
        <Skeleton variant="rectangular" width="100%" sx={{ height: 15, borderRadius: 1 }} />
        <Skeleton variant="rectangular" width="100%" sx={{ height: 15, borderRadius: 1 }} />
      </Stack>
    </Stack>
  </PricingPlanRootStyle>
);

export const PaymentPlanSkeleton = ({ isMobile }) => (
  <Stack
    spacing={10}
    direction={isMobile ? 'column' : 'row'}
    justifyContent="center"
    sx={{ ...(isMobile && { alignItems: 'center' }) }}
  >
    {[...new Array(2)].map((_, index) => (
      <SkeletonCard key={index} />
    ))}
  </Stack>
);
