// material
import { useCallback, useState } from 'react';
import { defaultTo, filter } from 'lodash';
import useMobile from 'src/hooks/useMobile';
import { Switch, Typography, Stack, Box, TextField, MenuItem } from '@material-ui/core';
import { paramCase } from 'change-case';
import PricingPlanCard from './PricingPlanCard';
import { PaymentPlanSkeleton } from './PaymentPlanSkeleton';
import { CURRENCIES } from './constants';

export default function PricingPlans({
  enablePicker = true,
  currency,
  isLargeView,
  isLoggedIn,
  loading,
  plans,
  currentPlan,
  handleAction,
  handleChangeCurrency
}) {
  const [monthly, setMonthly] = useState(false);
  const isMobile = useMobile();

  const getPlans = useCallback(() => {
    if (monthly) {
      return filter(
        defaultTo(plans, []),
        (o) =>
          paramCase(defaultTo(o.frequency, '')) === 'monthly' &&
          (enablePicker ? paramCase(defaultTo(o.currency?.code, '')) === paramCase(currency) : true)
      );
    }
    return filter(
      defaultTo(plans, []),
      (o) =>
        paramCase(defaultTo(o.frequency, '')) === 'yearly' &&
        (enablePicker ? paramCase(defaultTo(o.currency?.code, '')) === paramCase(currency) : true)
    );
  }, [enablePicker, currency, plans, monthly]);

  const data = getPlans();

  const PaymentTermSwitch = () => (
    <Stack direction="row" alignItems="center" justifyContent="center">
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ mr: 1.5, ...(monthly && { color: (theme) => theme.palette.primary.main }) }}
      >
        <strong>MONTHLY</strong>
      </Typography>
      <Switch defaultChecked={!monthly} onChange={() => setMonthly((prev) => !prev)} />
      <Box sx={{ display: 'flex' }}>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            mr: 1.5,
            ...(!monthly && { color: (theme) => theme.palette.primary.main }),
            alignSelf: 'center'
          }}
        >
          <strong>YEARLY</strong>
        </Typography>
        <Typography
          gutterBottom
          component="span"
          variant="overline"
          sx={{
            color: 'text.secondary',
            padding: 0,
            margin: 0,
            alignSelf: 'center'
          }}
        >
          (Save 25%)
        </Typography>
      </Box>
    </Stack>
  );

  const PaymentCurrencySwitch = () => (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <TextField
        select
        variant="outlined"
        size="small"
        value={currency}
        onChange={(e) => handleChangeCurrency(e.target.value)}
      >
        {CURRENCIES.map((curr) => (
          <MenuItem key={curr.key} value={curr.value}>
            <Stack spacing={1.5} direction="row" alignItems="center">
              <Box component="img" src={curr.icon} alt={curr.value} sx={{ width: 20, height: 20 }} />
              <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 600, fontSize: '16px' }}>
                {curr.value}
              </Typography>
            </Stack>
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );

  return loading ? (
    <Box>
      <PaymentPlanSkeleton isMobile={isMobile} />
    </Box>
  ) : (
    <>
      <Stack spacing={isMobile ? 2 : 5} direction={isMobile ? 'column' : 'row'} justifyContent="center">
        <PaymentTermSwitch />
        {enablePicker && <PaymentCurrencySwitch />}
      </Stack>

      <Box>
        <Stack
          spacing={10}
          direction={isMobile ? 'column' : 'row'}
          justifyContent="center"
          sx={{ ...(isMobile && { alignItems: 'center' }) }}
        >
          {data.map((plan) => (
            <PricingPlanCard
              key={plan.id}
              isLargeView={isLargeView}
              isLoggedIn={isLoggedIn}
              plan={plan}
              currentPlan={currentPlan}
              handleAction={handleAction}
            />
          ))}
        </Stack>
      </Box>
    </>
  );
}
