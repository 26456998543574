import PropTypes from 'prop-types';
// material
import { styled } from '@material-ui/core/styles';
import { Typography, Box, Stack } from '@material-ui/core';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(8, 2)
}));

// ----------------------------------------------------------------------

EmptyContent.propTypes = {
  title: PropTypes.string.isRequired,
  img: PropTypes.string,
  description: PropTypes.string
};

export default function EmptyContent({
  title,
  description,
  showImg = true,
  img,
  height = 240,
  action,
  titleProps,
  ...other
}) {
  return (
    <RootStyle {...other}>
      <Box
        component="img"
        alt="empty content"
        {...(showImg && { src: img || '/static/illustrations/illustration_empty_content.svg' })}
        sx={{ height, mb: 3 }}
      />

      <Stack spacing={4}>
        <Box>
          {title && (
            <Typography variant="h5" gutterBottom {...titleProps}>
              {title}
            </Typography>
          )}

          {description && (
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {description}
            </Typography>
          )}
        </Box>
        {action}
      </Stack>
    </RootStyle>
  );
}
